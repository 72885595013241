<template>
  <div class="columns is-centered is-marginless" style="height: 100%">
    <div
      class="column is-4"
      style="height: 100%"
    >
      <div class="card">
        <div class="card-header">
          <h1 class="card-header-title">
            Book Appointment
          </h1>
        </div>
        <div class="card-content">
          <p>Book an appointment with a doctor in 2 minutes</p>
          <form v-if="!authUser"  @submit.prevent class="mb-5">
            <b-field
              custom-class=""
              :type="userError.firstName ? 'is-danger' : ''"
              :message="userError.firstName"
            >
              <b-input
                placeholder="FirstName"
                custom-class="custum-input"
                v-model="user.firstName"> </b-input>
            </b-field>
            <b-field
              custom-class=""
              :type="userError.lastName ? 'is-danger' : ''"
              :message="userError.lastName"
            >
              <b-input
                placeholder="LastName"
                custom-class="custum-input"
                v-model="user.lastName"> </b-input>
            </b-field>
            <b-field
              custom-class=""
              :type="userError.mobileNumber ? 'is-danger' : ''"
              :message="userError.mobileNumber"
            >
              <b-input
                placeholder="MobileNumber"
                custom-class="custum-input"
                type="tel"
                v-model="user.mobileNumber"> </b-input>
            </b-field>
            <b-field>
              <b-input
                  placeholder="Email"
                  type="email"
                  custom-class="custum-input"
                  v-model="user.email"
                  >
              </b-input>
            </b-field>
            <div class="columns is-centered">
              <div class="column">
                <b-button
                  type="is-primary"
                  expanded
                  @click="createUser"
                  class="has-text-weight-bold"
                >
                  Continue
                </b-button>
              </div>
            </div>
          </form>
          <form v-else @submit.prevent class="mb-5">
            <b-field
              label="Date"
              custom-class=""
              :type="appointmentError.startAt ? 'is-danger' : ''"
              :message="appointmentError.startAt"
            >
              <b-datetimepicker
                placeholder="Select date"
                icon="calendar-today"
                v-model="appointment.startAt"
                :min-datetime="minDatetime"
                :disabled="pendingAppointments"
                >
              </b-datetimepicker>
            </b-field>
            <b-field
              label="Description"
              custom-class=""
              :type="appointmentError.patientDesc ? 'is-danger' : ''"
              :message="appointmentError.patientDesc"
            >
              <b-input
                placeholder="Description"
                custom-class="custum-input"
                v-model="appointment.patientDesc"
                type="textarea"
                :disabled="pendingAppointments"
                >
              </b-input>
            </b-field>
            <div class="columns is-centered">
              <div class="column">
                <b-button
                  type="is-primary"
                  expanded
                  @click="createAppointment"
                  class="has-text-weight-bold"
                  :disabled="pendingAppointments"
                >
                  Book Appointment
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-if="authUser" class="has-text-centered mt-3">
        <p class="is-size-7 has-text-grey-light">
          <!-- eslint-disable max-len -->
          Signed in as <br> <strong>{{[ authUser.firstName, authUser.lastName].join(' ') }}</strong> <br> ({{ authUser.email }})
        </p>
        <div class="has-text-centered mt-2">
          <b-button
          type="is-danger"
          size="is-small"
          @click="logout"
          class="has-text-weight-bold"
          >
          Sign out
          </b-button>
        </div>
      </div>
      <div v-else class="has-text-centered mt-3">
        <p class="has-text-grey-light is-size-7">
          Already have an account ?
        </p>
        <b-button
          size="is-small"
          type="is-primary"
          @click="$router.push({ name: 'Login' })"
          class="has-text-weight-bold"
        >
          Sign in
        </b-button>
      </div>
      <b-loading :is-full-page="false" v-model="loading" ></b-loading>
    </div>
  </div>
</template>

<script>
import { auth } from '@/pluging/firebase';
import { mapMutations, mapGetters } from 'vuex';
import { validationAppointmentPatient, validation } from '@/services/validation';
import { CreateUser } from '@/api/user';
import { GetAppointmentsUser, CreateAppointmentPatient } from '@/api/appointment';

export default {
  name: 'BookAppointment',
  metaInfo() {
    return {
      title: 'Book Appointment',
    };
  },
  data() {
    return {
      loading: false,
      appointment: {
        startAt: null,
        patientDesc: '',
      },
      appointmentError: {
        startAt: '',
        patientDesc: '',
      },
      user: {
        email: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
      },
      userError: {
        email: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
      },
      minDatetime: new Date(),
      pendingAppointments: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: 'user',
      userId: 'userId',
    }),
    appUrl() {
      return process.env.VUE_APP_URL;
    },
  },
  methods: {
    ...mapMutations({
      setUserId: 'SET_USER_ID',
      setEmail: 'SET_EMAIL',
      setUser: 'SET_USER',
      resetStore: 'RESET',
    }),

    async createUser() {
      if (!validation(this.user, this.userError, 2000)) return null;

      const loadingComponent = this.$buefy.loading.open();

      try {
        const userAuth = await auth.signInAnonymously();
        this.setUserId(userAuth.user.uid);
        this.setEmail(this.user.email);

        const res = (await CreateUser(this.user, this.userId)).data;
        this.setUser(res.user);
        return true;
      } catch (error) {
        this.showError(error);
        if (error.response && error.response.data && error.response.data.name === 'EmailAlreadyExist') {
          this.$router.push({
            name: 'Login',
            params: {
              alert: {
                title: 'Email already exist',
                message: 'The email is already registered, you can sign in',
                type: 'is-primary',
              },
              email: this.user.email,
            },
          });
        }
        return false;
      } finally {
        loadingComponent.close();
      }
    },

    async createAppointment() {
      if (!validationAppointmentPatient(this.appointment, this.appointmentError, 2000)) return null;

      const loadingComponent = this.$buefy.loading.open();
      try {
        const url = (await CreateAppointmentPatient(this.appointment)).data;
        loadingComponent.close();
        this.redirect(url);
        return true;
      } catch (error) {
        this.showError(error);
        return false;
      } finally {
        loadingComponent.close();
      }
    },
    redirect(url) {
      if (this.$route.query.embed && window.parent !== null && !window.parent.closed) {
        const parent = window.parent;
        window.close();
        parent.location = url;
      } else {
        window.location = url;
        // this.$router.push({ name: 'Login' });
      }
    },
    async logout() {
      await auth.signOut();
      this.resetStore();
      this.$router.push({ name: 'Login' });
    },
  },
  async mounted() {
    if (!this.authUser) return;
    this.loading = true;
    try {
      const res = (await GetAppointmentsUser(true)).data;
      this.appointments = res.appointments;
      if (this.appointments.length) {
        this.pendingAppointments = true;
        this.$buefy.toast.open({
          duration: 5000,
          message: 'You already have create an appointment that has not yet started',
          position: 'is-bottom',
          type: 'is-danger',
        });
      }
    } catch (error) {
      this.showError(error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
